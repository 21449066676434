export function MenuIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.223 0.201348C15.7062 -0.0671161 16.2938 -0.0671161 16.777 0.201348L31.1768 8.20135C31.6847 8.48354 31.9997 9.01893 31.9997 9.6C31.9997 10.1811 31.6847 10.7165 31.1768 10.9987L16.777 18.9987C16.2938 19.2671 15.7062 19.2671 15.223 18.9987L0.823248 10.9987C0.315313 10.7165 0.000291414 10.1811 0.000291414 9.6C0.000291414 9.01893 0.315313 8.48354 0.823248 8.20135L15.223 0.201348ZM4.8948 9.6L16 15.7697L27.1052 9.6L16 3.43033L4.8948 9.6Z"
        fill="#636363"
      />
      <path
        d="M0.201636 15.223C0.630769 14.4505 1.60484 14.1722 2.37728 14.6013L16 22.1697L29.6227 14.6013C30.3952 14.1722 31.3692 14.4505 31.7984 15.223C32.2275 15.9954 31.9492 16.9695 31.1768 17.3987L16.777 25.3987C16.2938 25.6671 15.7062 25.6671 15.223 25.3987L0.823248 17.3987C0.0508079 16.9695 -0.227497 15.9954 0.201636 15.223Z"
        fill="#636363"
      />
      <path
        d="M0.201636 21.623C0.630769 20.8505 1.60484 20.5722 2.37728 21.0013L16 28.5697L29.6227 21.0013C30.3952 20.5722 31.3692 20.8505 31.7984 21.623C32.2275 22.3954 31.9492 23.3695 31.1768 23.7987L16.777 31.7987C16.2938 32.0671 15.7062 32.0671 15.223 31.7987L0.823248 23.7987C0.050808 23.3695 -0.227497 22.3954 0.201636 21.623Z"
        fill="#636363"
      />
    </svg>
  );
}
