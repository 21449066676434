export function MenuLink({ title, to, size = "text-md", addClassName = "" }) {
  return (
    <a
      href={to}
      className={`uppercase block ${size} hover:text-primary transition-colors ${addClassName}`}
    >
      {title}
    </a>
  );
}
