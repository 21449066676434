import { MenuHeader } from "../MenuHeader";
import { MenuLink } from "../MenuLink";

export function NewsSection() {
  return (
    <>
      <MenuHeader title="news" />

      <MenuLink title="know" to="/news/know" size="text-2xl" />
      <MenuLink title="innovate" to="/news/innovate" size="text-2xl" />
      <MenuLink title="thrive" to="/news/thrive" size="text-2xl" />
      <MenuLink title="create" to="/news/create" size="text-2xl" />
      {/* <MenuLink title="place" to="/news/place" size="text-2xl" />
      <MenuLink title="impact" to="/news/impact" size="text-2xl" />
      <MenuLink title="venture" to="/news/venture" size="text-2xl" /> */}
    </>
  );
}
