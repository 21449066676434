export function CatalystIcon() {
  return (
    <svg
      width="156"
      height="32"
      viewBox="0 0 156 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0201 12.4932L52.7727 13.3332C52.0003 12.1862 50.693 11.466 49.1559 11.466C46.5414 11.466 44.5622 13.3583 44.5622 16.1524C44.5622 18.9466 46.5395 20.8389 49.1559 20.8389C50.6833 20.8389 51.9829 20.1418 52.7649 18.9794L53.9872 19.8715C52.9851 21.3564 51.2105 22.2736 49.1559 22.2736C45.5894 22.2736 43 19.7093 43 16.1524C43 12.5956 45.5894 10.0312 49.1559 10.0312C51.2356 10.0312 53.0874 10.9658 54.0201 12.4932Z"
        fill="white"
      />
      <path
        d="M70.1919 22.0941H68.5873L67.28 18.8501H61.5161L60.2165 22.0941H58.6621L63.4934 10.209H65.3529L70.1919 22.0941ZM62.0838 17.4328H66.7104L64.401 11.6842L62.0838 17.4328Z"
        fill="white"
      />
      <path
        d="M82.9152 11.6263H78.866V22.0941H77.3714V11.6263H73.3125V10.209H82.9152V11.6263Z"
        fill="white"
      />
      <path
        d="M98.9682 22.0941H96.4386L95.4036 19.327H90.3348L89.2998 22.0941H86.8301L91.4567 10.209H94.3435L98.9701 22.0941H98.9682ZM91.1497 17.145H94.5887L92.874 12.5513L91.1497 17.145Z"
        fill="white"
      />
      <path
        d="M112.539 22.0941H103.889V10.209H106.258V19.8793H112.541V22.0961L112.539 22.0941Z"
        fill="white"
      />
      <path
        d="M123.931 17.595V22.0941H120.704V17.6374L116.221 10.209H119.922L122.325 14.7004L124.719 10.209H128.311L123.929 17.595H123.931Z"
        fill="white"
      />
      <path
        d="M140.237 13.8851C139.455 13.4429 138.275 12.8926 136.808 12.8926C135.941 12.8926 135.28 13.1301 135.28 13.665C135.28 14.335 136.495 14.4045 137.708 14.6421L138.09 14.7193C140.544 15.1943 141.792 16.3741 141.792 18.2008C141.792 21.2054 139.252 22.3177 136.723 22.3177C134.591 22.3177 132.656 21.8079 131.297 20.8405L132.876 18.2086C133.819 18.7782 134.956 19.4309 136.765 19.4309C137.632 19.4309 138.268 19.1934 138.268 18.6083C138.268 17.9884 137.528 17.8784 136.053 17.6235L135.603 17.5462C132.869 17.0789 131.731 15.8991 131.791 13.7944C131.851 11.5197 133.761 10 136.613 10C138.167 10 139.567 10.1622 141.63 11.2474L140.237 13.887V13.8851Z"
        fill="white"
      />
      <path
        d="M155.68 13.1808H152.301V22.0961H149.075V13.1808H145.695V10.209H155.68V13.1808Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#2BE2D8"
      />
      <path
        d="M7.24842 16C7.24842 11.1664 11.1664 7.24842 16 7.24842V0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32V24.7516C11.1664 24.7516 7.24842 20.8336 7.24842 16Z"
        fill="#2BE2D8"
      />
      <path
        d="M16 7.24805V24.7512C20.8336 24.7512 24.7516 20.8333 24.7516 15.9996C24.7516 11.166 20.8336 7.24805 16 7.24805Z"
        fill="#2BE2D8"
      />
    </svg>
  );
}
