export function FooterCatalystLogo() {
  return (
    <svg
      width="69"
      height="14"
      viewBox="0 0 69 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6338 5.46579L23.088 5.83328C22.7501 5.33147 22.1782 5.01636 21.5057 5.01636C20.3619 5.01636 19.4959 5.84426 19.4959 7.06669C19.4959 8.28912 20.361 9.11702 21.5057 9.11702C22.174 9.11702 22.7425 8.81205 23.0847 8.30348L23.6194 8.69378C23.181 9.34343 22.4046 9.74471 21.5057 9.74471C19.9454 9.74471 18.8125 8.62281 18.8125 7.06669C18.8125 5.51057 19.9454 4.38867 21.5057 4.38867C22.4156 4.38867 23.2257 4.79756 23.6338 5.46579Z"
        fill="white"
      />
      <path
        d="M30.7084 9.66655H30.0063L29.4344 8.24728H26.9127L26.3441 9.66655H25.6641L27.7778 4.4668H28.5913L30.7084 9.66655ZM27.161 7.6272H29.1852L28.1748 5.11222L27.161 7.6272Z"
        fill="white"
      />
      <path
        d="M36.2754 5.08688H34.5039V9.66655H33.85V5.08688H32.0742V4.4668H36.2754V5.08688Z"
        fill="white"
      />
      <path
        d="M43.2987 9.66655H42.192L41.7392 8.45595H39.5216L39.0688 9.66655H37.9883L40.0124 4.4668H41.2754L43.2995 9.66655H43.2987ZM39.8781 7.50132H41.3827L40.6325 5.49154L39.8781 7.50132Z"
        fill="white"
      />
      <path
        d="M49.2359 9.66655H45.4512V4.4668H46.4877V8.69756H49.2367V9.66739L49.2359 9.66655Z"
        fill="white"
      />
      <path
        d="M54.219 7.69816V9.66655H52.8073V7.71675L50.8457 4.4668H52.4652L53.5161 6.4318L54.5637 4.4668H56.135L54.2181 7.69816H54.219Z"
        fill="white"
      />
      <path
        d="M61.3528 6.07474C61.0107 5.88128 60.4945 5.64051 59.8525 5.64051C59.4732 5.64051 59.1842 5.74442 59.1842 5.97843C59.1842 6.27158 59.7156 6.30199 60.2461 6.4059L60.4134 6.43969C61.4872 6.64751 62.0329 7.16369 62.0329 7.96287C62.0329 9.27738 60.922 9.76398 59.8153 9.76398C58.8826 9.76398 58.0361 9.54096 57.4414 9.11771L58.1325 7.96625C58.5447 8.21546 59.0423 8.50101 59.8339 8.50101C60.2132 8.50101 60.4911 8.3971 60.4911 8.14112C60.4911 7.86994 60.1676 7.82179 59.5222 7.71027L59.3253 7.67648C58.1291 7.47204 57.6315 6.95587 57.6577 6.03503C57.6839 5.03986 58.5194 4.375 59.7671 4.375C60.4472 4.375 61.0597 4.44596 61.9619 4.92074L61.3528 6.07558V6.07474Z"
        fill="white"
      />
      <path
        d="M68.1107 5.76695H66.6323V9.66739H65.2206V5.76695H63.7422V4.4668H68.1107V5.76695Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="white"
      />
      <path
        d="M3.17118 7C3.17118 4.88529 4.88529 3.17118 7 3.17118V0C3.13396 0 0 3.13396 0 7C0 10.866 3.13396 14 7 14V10.8288C4.88529 10.8288 3.17118 9.11471 3.17118 7Z"
        fill="white"
      />
      <path
        d="M7 3.1709V10.8285C9.11471 10.8285 10.8288 9.11443 10.8288 6.99972C10.8288 4.885 9.11471 3.1709 7 3.1709Z"
        fill="white"
      />
    </svg>
  );
}
