import { MenuHeader } from "../MenuHeader";
import { MenuLink } from "../MenuLink";

export function ConnectSection() {
  return (
    <>
      <MenuHeader title="connect" addClassName="mb-10" />

      <div className="space-y-5">
        <MenuLink
          title="contribute content"
          size="text-sm"
          to="/contribute"
          addClassName="font-normal"
        />
        <MenuLink
          title="meet the team"
          size="text-sm"
          to="/news/meet-the-team"
          addClassName="font-normal"
        />
        <MenuLink
          title="support the catalyst"
          size="text-sm"
          to="/news/support-catalyst"
          addClassName="font-normal"
        />
      </div>
    </>
  );
}
