import * as singleSpa from "single-spa";
import "../public/styles.css";

import { useState } from "react";
import { CityverseIcon } from "./components/CityverseIcon";
import { CatalystIcon } from "./components/CatalystIcon";
import { MenuIcon } from "./components/MenuIcon";
import { CloseIcon } from "./components/CloseIcon";
import { MegaMenu } from "./components/MegaMenu";

export default function Root(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onClickMenuToggle = () => {
    window.scrollTo(0, 0);
    setIsMenuOpen(!isMenuOpen);
  };
  const buttonHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    singleSpa.navigateToUrl(event.currentTarget.href);
    event.preventDefault();
  };
  return (
    <>
      <header>
        <div className="md:container mx-auto">
          <div className="md:h-24 flex items-center pl-8 pr-8 md:pl-0 md:pr-0 bg-black md:bg-transparent">
            <div className="hidden md:flex h-full justify-center items-center bg-black rounded-tr-2xl rounded-br-2xl mr-2 p-4">
              <a
                href="https://cityverse.life/"
                title="Cityverse Home Page"
                onClick={buttonHandler}
              >
                <CityverseIcon />
              </a>
            </div>

            <div className="h-full flex items-center bg-black justify-between rounded-2xl grow py-7 md:p-6 space-x-8">
              <a href="/news" onClick={buttonHandler}>
                <CatalystIcon />
              </a>
              {/*
            <div className="flex space-x-5 justify-end grow">
              <CreateButton />
              <Search />
            </div> */}

              {/* <a className="" href="/home" onClick={buttonHandler}>
            <AvatarIcon />
          </a> */}
            </div>

            <div className="h-full flex justify-center items-center bg-black rounded-2xl ml-2 md:p-4">
              <button
                aria-label={isMenuOpen ? "close" : "open"}
                className="inline cursor-pointer"
                onClick={onClickMenuToggle}
              >
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </button>
            </div>
          </div>
        </div>
      </header>
      <MegaMenu isOpen={isMenuOpen} />
    </>
  );
}
