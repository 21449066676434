import { MenuHeader } from "../MenuHeader";
import { MenuLink } from "../MenuLink";

export function MoreSection() {
  return (
    <>
      <MenuHeader title="more" addClassName="my-10" />

      <div className="space-y-5">
        <MenuLink
          title="about"
          size="text-xs"
          to=""
          addClassName="font-normal"
        />
        <MenuLink
          title="privacy policy"
          size="text-xs"
          to="/news/privacy-policy"
          addClassName="font-normal"
        />
        <MenuLink
          title="terms of use"
          size="text-xs"
          to=""
          addClassName="font-normal"
        />
        <MenuLink
          title="site map"
          size="text-xs"
          to=""
          addClassName="font-normal"
        />
      </div>
    </>
  );
}
