export function CloseIcon() {
  return (
    <svg
      width="32"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16.707"
        y="5.77246"
        width="14.8023"
        height="1.43248"
        rx="0.71624"
        transform="rotate(135 16.707 5.77246)"
        fill="#2BE2D8"
        stroke="#2BE2D8"
      />
      <rect
        x="15.6934"
        y="16.2402"
        width="14.8023"
        height="1.43248"
        rx="0.716239"
        transform="rotate(-135 15.6934 16.2402)"
        fill="#2BE2D8"
        stroke="#2BE2D8"
      />
    </svg>
  );
}
