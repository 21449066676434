import { MenuHeader } from "../MenuHeader";
import { MenuLink } from "../MenuLink";

export function OriginalsSection() {
  return (
    <>
      <MenuHeader title="catalyst originals" addClassName="mb-10" />

      <div className="space-y-5">
        <MenuLink
          title="st. pete x"
          to="https://stpetersburggroup.com/stpetex"
          size="text-sm"
          addClassName="font-normal"
        />
        <MenuLink
          title="impact catalyst"
          size="text-sm"
          to="/news/impact"
          addClassName="font-normal"
        />
        <MenuLink
          title="venture catalyst"
          size="text-sm"
          to="/news/venture"
          addClassName="font-normal"
        />
        <MenuLink
          title="welcome to florida"
          size="text-sm"
          to="/news/tags/4172"
          addClassName="font-normal"
        />
      </div>
    </>
  );
}
