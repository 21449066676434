export function CityverseIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.37485 12.5778C4.01056 12.1112 3.71501 11.5898 3.50423 11.0261H17.7984C17.8946 10.6131 17.9622 10.1887 18 9.75627H3.18577C3.15255 9.50184 3.13422 9.24284 3.13422 8.98042C3.13422 8.718 3.1514 8.45787 3.18577 8.20458H17.9989C17.9622 7.77215 17.8946 7.34772 17.7972 6.9347H3.50423C3.71501 6.37107 4.01056 5.84851 4.37485 5.383H17.2794C15.8865 2.21344 12.711 0 9.01661 0C4.03691 0 0 4.02071 0 8.98042C0 13.9401 4.03691 17.9608 9.01661 17.9608C12.711 17.9608 15.8865 15.7474 17.2794 12.579H4.37485V12.5778ZM9.01661 3.12278C10.2286 3.12278 11.3535 3.48789 12.2894 4.11313H5.74263C6.67855 3.48789 7.80462 3.12278 9.01546 3.12278H9.01661ZM9.01661 14.8381C7.80462 14.8381 6.67969 14.473 5.74378 13.8477H12.2906C11.3547 14.473 10.2286 14.8381 9.01776 14.8381H9.01661Z"
        fill="url(#paint0_linear_311_3493)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_311_3493"
          x1="0"
          y1="8.97928"
          x2="17.9989"
          y2="8.97928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C00FA" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
