import ReactModal from "react-modal";
import { ConnectSection } from "./sections/ConnectSection";
import { FooterCatalystLogo } from "./sections/FooterCatalystLogo";
import { MoreSection } from "./sections/MoreSection";
import { NewsSection } from "./sections/NewsSection";
import { OriginalsSection } from "./sections/OriginalsSection";
import { PeopleSection } from "./sections/PeopleSection";
import { ZapsSection } from "./sections/ZapsSection";

export function MegaMenu({ isOpen }) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="container"
      className="w-full h-full overflow-y-scroll fixed md:absolute md:top-48 top-20 bottom-0"
      shouldFocusAfterRender={false}
      closeTimeoutMS={200}
      bodyOpenClassName="overflow-hidden"
    >
      <div className="md:container mx-auto md:h-full pl-[20%] pr-0 md:px-24 pt-10 pb-20 md:py-10 bg-black text-white md:rounded-tr-2xl md:rounded-tl-2xl">
        <div className="grid md:grid-cols-5 gap-10 mb-20">
          <div className="col-span-1 space-y-10">
            <NewsSection />
          </div>
          <div className="col-span-1">
            {/* <PeopleSection /> */}
            {/* <ZapsSection /> */}
          </div>
          <div className="col-span-1">
            <OriginalsSection />
          </div>
          <div className="col-span-1 space-y-10"></div>
          <div className="col-span-1">
            <ConnectSection />
            <MoreSection />
          </div>
        </div>
        <div className="flex justify-between border-t py-4 text-xs pr-4 md:pr-0">
          <FooterCatalystLogo />
          <div>&copy; 2023 CATALYST</div>
        </div>
      </div>
    </ReactModal>
  );
}
